import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Products from "./pages/products";

import Login from "./pages/login";


function App() {
  return (
    <div className="App">

      <Router>
        <Routes>
          <Route path="/" element={<Login />} exact/>
          
          <Route path="/products" element={<Products />} />
          
          
          {/* <Route path="/home" element={<HomePage />} /> */}
          {/* <Route path="/about" element={<AboutPage />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
