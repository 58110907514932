import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../assets/zara-logo-white.png';

export default function NavBar() {
    return(
        <>
      <Navbar className="navbar" data-bs-theme="dark">
        <Container>
          <Navbar.Brand href="/"><img src={Logo} alt="logo" className='logo'/></Navbar.Brand>
          <Nav className="me-auto">
            
            {/* <Nav.Link href="/products">Products</Nav.Link> */}
            
           
          </Nav>
          <Navbar.Text>
            <a href="/">Sign Out</a>
          </Navbar.Text>
        </Container>
      </Navbar>
      </>
    )
}