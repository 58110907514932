// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC1isCBOTOsyd5_uBPImVFUD3JUM16IbcM",
  authDomain: "zaratech-cff9d.firebaseapp.com",
  projectId: "zaratech-cff9d",
  storageBucket: "zaratech-cff9d.appspot.com",
  messagingSenderId: "272373668109",
  appId: "1:272373668109:web:cc7d90bf64e66b3cc43844",
  measurementId: "G-L6KKECPVS0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firestore
const db = getFirestore(app);

// Export Firestore functions and variables
export default db;
// const analytics = getAnalytics(app);
