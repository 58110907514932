import * as React from 'react';
import db from '../firebase';
import { useEffect,useRef, useState } from "react";
import { collection, getDocs, getDoc, setDoc, doc, deleteDoc, updateDoc, arrayUnion } from "firebase/firestore";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Swal from 'sweetalert2';
import Card from 'react-bootstrap/Card';
import NavBar from '../components/navbar';
import { FaMinus, FaPlus } from 'react-icons/fa';

const columns = [
    { field: 'name', headerName: 'Product', width: 130 },
    { field: 'name', headerName: 'Equipment', width: 130 },
    { field: 'description', headerName: 'Description', width: 600 },
    { field: 'longDescription', headerName: 'Long Description', width: 600 },
    { field: 'feature', headerName: 'Features', width: 130 },
    { field: 'imageUrls', headerName: 'Images', width: 130 },
    { field: 'fileUrls', headerName: 'Brochure', width: 130 },
    { field: 'action', headerName: 'Action', width: 130 },
];

export default function Products() {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState([]);
    const hasFetchedData = useRef(false); // Track if data has been fetched
    const [arrayData, setArrayData] = useState([]);
    const [events, setEvents] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadPreset] = useState('ml_default');
    const [error, setError] = useState(null);
    const [collections, setCollections] = useState([]);
    const [feature, setFeatures] = useState([{ description: '' }]);
    const [featureVisible, setFeatureVisible] = useState(feature.map(() => false));
    const [equipment, setEquipment] = useState([{ equipmentName: '', description: '',longDescription: '', image: [], brochure: [] }]);
    const [selectedDocuments, setSelectedDocuments] = useState({});

    const openModal = (data) => {
        setShowModal(true);
        setModalData(data);
    }

    const fetchCollection = async (item) => {
        setUploading(true);
        try {
            // Fetch the documents from the collection
            const querySnapshot = await getDocs(collection(db, item));
            
            // Map through the documents and structure them into objects
            const documents = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
    
            console.log("Fetched documents for", item, ":", documents);
    
            // Append the documents of the current collection as an array

            setEvents(prevEvents => [...prevEvents, documents]);
    
        } catch (err) {
            setError(err.message);
        } finally {
            setUploading(false);
        }
    };
    
    useEffect(() => {
        const fetchData = async () => {
    
            // Prevent fetching multiple times in development
            if (hasFetchedData.current) return;
    
            hasFetchedData.current = true;
    
            try {
                const docRef = doc(db, "collectionsList", "collectionsDoc");
                const docSnap = await getDoc(docRef);
                
                const fetchedArray = docSnap.data().collectionsArray || [];
                
                setArrayData(fetchedArray);
    
                if (fetchedArray.length > 0) {
                    await Promise.all(fetchedArray.map(fetchCollection));
                }
    
            } catch (err) {
                setError(err.message);
            }
        };
    
        fetchData();
    }, []);
    

    const handleFileChange = (event) => {
        setFiles(event.target.files);
    };

    const handleImageChange = (event) => {
        setImages(event.target.files);
    };

    const handleFeatureChange = (index, event) => {
        const values = [...feature];
        values[index][event.target.name] = event.target.value;
        setFeatures(values);
    };

    const removeFeatureField = (index) => {
        const values = [...feature];
        values.splice(index, 1);
        setFeatures(values);
    };

    const addSubjectField = () => {
        setFeatures([...feature, { description: '' }]);
    };

    const createDocumentWithCustomID = async (collectionName, documentID, documentData) => {
        try {
            const docRef = doc(db, collectionName, documentID);
            await setDoc(docRef, documentData);
            console.log(`Document with ID ${documentID} has been created!`);
        } catch (error) {
            console.error("Error adding document with custom ID: ", error);
        }
    };
    const toggleFeatureVisibility = (index) => {
        const newVisible = [...featureVisible];
        newVisible[index] = !newVisible[index];
        setFeatureVisible(newVisible);
    };



    const handleSubmit = async (event) => {
        event.preventDefault();

        if (files.length === 0 || images.length === 0) {
            console.error('No files selected.');
            return;
        }

        setUploading(true);

        const imageUrls = [];
        const fileUrls = [];

        const uploadToCloudinary = async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', uploadPreset);
            const response = await axios.post(`https://api.cloudinary.com/v1_1/dado4z4xi/image/upload`, formData);
            return response.data.secure_url;
        };

        const uploadFileToCloudinary = async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', uploadPreset);
            const response = await axios.post(`https://api.cloudinary.com/v1_1/dado4z4xi/raw/upload`, formData);
            return response.data.secure_url;
        };

        // Upload images and files
        for (const file of images) {
            const imageUrl = await uploadToCloudinary(file);
            if (imageUrl) imageUrls.push(imageUrl);
        }

        for (const file of files) {
            const fileUrl = await uploadFileToCloudinary(file);
            if (fileUrl) fileUrls.push(fileUrl);
        }

        try {
            for (const equip of equipment) {
                if (equip.equipmentName) {
                    const eventDoc = {
                        category: categoryName,
                        name: equip.equipmentName,
                        description: equip.description,
                        longDescription: equip.longDescription,
                        imageUrls: imageUrls,
                        fileUrls: fileUrls,
                        feature: feature.map(desc => desc)
                    };
                    await createDocumentWithCustomID(categoryName, equip.equipmentName, eventDoc);
                }
            }

            //store the category names into an array

            const collectionsDocRef = doc(db, "collectionsList", "collectionsDoc");

            await updateDoc(collectionsDocRef, {
                collectionsArray: arrayUnion(categoryName)
            });

            Swal.fire({
                title: 'Success!',
                text: 'Product added successfully!',
                icon: 'success',
                confirmButtonText: 'Cool'
            }).then(() => {
                handleClose();
                window.location.reload();
            });


            // window.location.reload();
        } catch (error) {
            console.error('Error adding Product: ', error);
        } finally {
            setUploading(false);
        }




    };

    const handleModal = () => {
        setShowAddModal(true);
    };

    const handleClose = () => {
        setShowAddModal(false);
        // Reset the form
        setEquipment([{ equipmentName: '', description: '',longDescription: '',image: [], brochure: [] }]);
        setCategoryName('');
        setFiles([]);
        setImages([]);
        setShowModal(false);
    };

    const handleEquipmentChange = (index, event) => {
        const values = [...equipment];
        values[index][event.target.name] = event.target.value;
        setEquipment(values);
    };

    const addEquipmentField = () => {
        setEquipment([...equipment, { equipmentName: '', description: '',longDescription: '', image: [], brochure: [] }]);
    };

    const removeEquipmentField = (index) => {
        const values = [...equipment];
        values.splice(index, 1);
        setEquipment(values);
    };

    const handleDelete = async (category, documentName) => {
        try {
            await deleteDoc(doc(db, category, documentName));
            setEvents(events.filter(record => record.id !== documentName));
            for (const categoryArray of events) {
                if (categoryArray.length <= 1) {
                    for (const data of categoryArray) {
                        if (data.category == category) {
                            const updatedArrayData = arrayData.filter(item => item !== category);
                            const collectionsDocRef = doc(db, "collectionsList", "collectionsDoc");
                            await updateDoc(collectionsDocRef, {
                                collectionsArray: updatedArrayData
                            });
                        }
                    }
                }
            }
            Swal.fire({
                title: 'Success!',
                text: 'Product Deleted successfully!',
                icon: 'success',
                confirmButtonText: 'Cool'
            }).then(() => {
                window.location.reload();
            });
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Error deleting Product.',
                icon: 'error',
                confirmButtonText: 'Try Again'
            }).then(() => {
                window.location.reload();
            });
            console.error('Error deleting document:', error);
        }
    };

    const handleSelectChange = (collectionIndex, event) => {
        const selectedDocIndex = event.target.value;
        // Update the selected document for the specific collection
        setSelectedDocuments(prevState => ({
            ...prevState,
            [collectionIndex]: selectedDocIndex, // Map the index of the collection to the selected document index
        }));
    };

    return (
        <>
            <NavBar />
            <div className='events_container'>
                <div className='updates_table'>
                    <h1>Product Details</h1>
                    <button className="main-btn" onClick={handleModal}>Add Product</button>
                </div>
                <div className='updates_data'>
                    {/* <div>{events.map((row,id) => <p key={id} >{row}</p>)}</div> */}
                    <Table responsive>
                        <thead>
                            <tr>
                                {columns.map((col, id) => <th key={id} style={{textAlign: 'center'}}>{col.headerName}</th>)}
                            </tr>
                        </thead>
                        
                         <tbody>
                {events.map((collection, collectionIndex) => {
                    // Get the currently selected document index for this collection (default is 0)
                    const selectedDocIndex = selectedDocuments[collectionIndex] || 0;
                    const selectedDoc = collection[selectedDocIndex]; // Get the selected document

                    return (
                        <tr key={`collection-${collectionIndex}`} style={{textAlign: 'center'}}>
                            <td>{selectedDoc?.category}</td>
                            <td>
                                <select className='dropdown'
                                    value={selectedDocIndex} style={{color: '#547af8', fontWeight: 'bold'}}
                                    onChange={(e) => handleSelectChange(collectionIndex, e)}
                                >
                                    {collection.map((doc, docIndex) => (
                                        <option key={doc.id} value={docIndex}>
                                            {doc.name}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td>{selectedDoc?.description}</td>
                            <td style={{textAlign: 'justify'}}>{selectedDoc?.longDescription}</td>
                            <td>
                                <button className='main-btn feature-btn' onClick={() => openModal(selectedDoc?.feature)}>Features</button>
                            </td>
                            <td>{selectedDoc?.imageUrls?.length}</td>
                            {/* <td>
                                {selectedDoc?.fileUrls ? 'Present' : 'Not Present'}
                            </td> */}
                            <td>
                           {selectedDoc?.fileUrls ? (
                                    <span className="tick-icon">&#10003;</span>
                                ) : (
                                    <span className="cross-icon">&#10007;</span>
                                )}
                                </td>
                            <td>
                                <button onClick={() => handleDelete(selectedDoc.category,selectedDoc.id)} className='feature-btn custom_button'>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
                    </Table>
                </div>
                

                <Modal show={showAddModal} onHide={handleClose} dialogClassName="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Product</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="categoryName">
                                <Form.Label>Category Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter the Product Category Name"
                                    value={categoryName}
                                    onChange={(e) => setCategoryName(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <div className="subject-cards">
                                {equipment.map((equip, index) => (
                                    <Card key={index} className="mb-3">
                                        <Card.Header>
                                            <h5>Equipment {index + 1}</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Equipment Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="equipmentName"
                                                    value={equip.equipmentName}
                                                    placeholder="Enter Equipment Name"
                                                    onChange={(e) => handleEquipmentChange(index, e)}
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="description"
                                                    value={equip.description}
                                                    placeholder="Enter short Description"
                                                    onChange={(e) => handleEquipmentChange(index, e)}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Long Description</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    name="longDescription"
                                                    value={equip.longDescription}
                                                    placeholder="Enter long Description"
                                                    onChange={(e) => handleEquipmentChange(index, e)}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Upload Pictures</Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    placeholder="Upload the image"
                                                    multiple
                                                    onChange={handleImageChange}
                                                    required
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Upload Brochure</Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    placeholder="Upload the Brochure"
                                                    multiple
                                                    onChange={handleFileChange}
                                                    required
                                                />
                                            </Form.Group>
                                            <div className="subject-cards">
                                                {feature.map((feature, index) => (
                                                    <Card key={index} className="mb-3">
                                                        <Card.Header>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                                                                <h5>Feature {index + 1}</h5>
                                                                <Button variant="link" onClick={() => toggleFeatureVisibility(index)} style={{ padding: 0, minWidth: '20px' }}>
                                                                    {featureVisible[index] ? <FaMinus /> : <FaPlus />}
                                                                </Button>
                                                            </div>
                                                        </Card.Header>
                                                        {featureVisible[index] && (
                                                            <Card.Body>
                                                                
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label>Feature Description</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="description"
                                                                        value={feature.description}
                                                                        placeholder="Enter Description"
                                                                        onChange={(e) => handleFeatureChange(index, e)}
                                                                    />
                                                                </Form.Group>
                                                                <Button variant="danger" onClick={() => removeFeatureField(index)}>Remove Feature</Button>
                                                            </Card.Body>)}
                                                    </Card>
                                                ))}
                                                <Button variant="secondary" onClick={addSubjectField} className="mb-3">Add More Features</Button>
                                            </div>
                                            <Button variant="danger" onClick={() => removeEquipmentField(index)}>
                                                <FaMinus /> Remove Equipment
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                ))}
                                <Button variant="primary" onClick={addEquipmentField}>
                                    <FaPlus /> Add More Equipment
                                </Button>
                            </div>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>Close</Button>
                                <Button variant="primary" type="submit" disabled={uploading}>
                                    {uploading ? 'Uploading...' : 'Submit'}
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal.Body>
                </Modal>
                <Modal show={showModal} onHide={handleClose} dialogClassName="custom-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Equipment Features</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <ul>
                                {modalData.map((data,id) =>
                                    <li key={id}>{data.description}</li>
                                    )}
                            </ul>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}
