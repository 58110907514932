import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Swal from 'sweetalert2';
import MainLogo from '../assets/zara-logo.png';

export default function Login(){
    const [validated, setValidated] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return; // Exit the function if the form is invalid
          }
            if(username == 'admin' && password == 'zara@123') {
                setValidated(true);
                navigate('/products');
            }
            else {
                Swal.fire({
                    title: 'Error!',
                    text: 'Invalid Username or Password',
                    icon: 'error',
                    confirmButtonText: 'Try Again'
                  }).then(() => {
                    // Redirect after alert is closed
                    navigate('/');
                  });
            } 
          };
    return(
        <>
            <img src={MainLogo} alt='main logo' className='main_logo' />
        <div className='login_main'>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group as={Col} md="14" controlId="validationCustomUsername">
            <Form.Label>Username</Form.Label>
            <InputGroup hasValidation>
                {/* <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text> */}
                <Form.Control
                type="text"
                placeholder="Username"
                aria-describedby="inputGroupPrepend"
                onChange={(e) => setUsername(e.target.value)}
                className='form_input'
                required
                />
                <Form.Control.Feedback type="invalid">
                Please choose a username.
                </Form.Control.Feedback>
            </InputGroup>
            </Form.Group>
        <Form.Group as={Col} md="14" controlId="validationCustom03">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Enter Your Password" required onChange={(e) => setPassword(e.target.value)} className='form_input'/>
          <Form.Control.Feedback type="invalid">
            Please provide a valid password.
          </Form.Control.Feedback>
        </Form.Group>
      <Button type="submit" className='form_input submit_button'>Login</Button>
    </Form>
    </div>
    </>

    );
}